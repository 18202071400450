<template>
  <v-app class>
    <div class="row">
      <div class="col-md-12 ml-auto mr-auto">
        <v-container>
          <div class="mb-4 mt-8 d-flex ">
            <div>
              <v-alert v-model="alert"
                       border="left"
                       dark
                       dismissible
                       class="alert"
                       :class="{'alert-error':alertType=='error', 'alert-success':alertType=='success'}">
                {{alertMessage}}
              </v-alert>
            </div>
            <div class="col-md-11 text-center">
              <h1>Allowances</h1>
            </div>
            <div class="col-md-1">
              <Button :btnType="'Submit'" :label="'+'" @onClick="Add" class="mr-2" />
            </div>
          </div>
          <v-row>
            <v-col cols="3">
              <SelectField class="pt-5"
                           :items="salaryPeriods"
                           v-model="formData.salaryPeriod"
                           :label="'Salary Cycle'"
                           :itemText="'description'"
                           :itemValue="'id'"
                           :returnObject="true"
                           @onChange="changeSalaryPeriod" />
            </v-col>
          </v-row>
          <v-text-field label="Search" v-model="searchVal" @input="searchFunc"></v-text-field>
          <div v-if="loadingData" class="text-center">
            <v-progress-circular indeterminate
                                 color="primary"></v-progress-circular>
          </div>
          <v-simple-table v-else>
            <template v-slot:default>
              <thead>
                <tr>
                  <th class="sticky-col">
                    Name
                  </th>
                  <th class="sticky-col">
                    Company
                  </th>
                  <th>
                    Allowances
                  </th>
                  <th>
                    Summary
                  </th>
                  <th>
                    Status
                  </th>
                  <th>

                  </th>
                  <th>

                  </th>
                  <th>
                    Cycle
                  </th>
                  <th v-for="item in headers.dateArr" :key="item.text">
                    {{item.text}}
                  </th>

                </tr>
              </thead>
              <tbody>
                <tr v-for="item in headers.dataArr" :key="item.id">
                  <td class="sticky-col">{{ item.employeeName}}</td>
                  <td class="sticky-col">{{ item.contractEmployerName}}</td>
                  <td>{{item.allowance}}</td>
                  <td>{{item.summary}}</td>
                  <td>{{item.approvalStatus}}</td>
                  <td>
                    <span v-if="item.approvalStatus !== 'Approved'">
                      <Button :btnType="'Forward'" :color="'primary'" :label="'Forward'" @onClick="openPersonnelSearch(item)">
                      </Button>
                    </span>
                  </td>
                  <td>
                    <span v-if="item.approvalCount === 0">
                      <Button :btnType="'Cancel'" :label="'Delete'" @onClick="openDeleteModal(item)">
                      </Button>
                    </span>
                  </td>
                  <td>{{item.salaryPeriodDescription}}</td>
                  <td v-for="date in headers.dateArr" :key="date.text">
                    <div class="cursor" @click="edit(item, value={date, info:item.requestInfo})">
                      <p class="allowance-day">{{filterVal(item.requestInfo, date).value}}</p>
                      <span class="tooltiptext">Click to edit</span>
                    </div>
                  </td>
                  <!-- <td v-if="action.id === 3">
    <v-select class="pt-5" :items="item.approvers" item-value="approver" item-text="approver" label="Approvers" @change="approverSelected($event , item)" return-object outlined dense/>
  </td> -->
                  <!-- <td v-for="item in headers" :key="item.date">
    <input
      type="checkbox"
      class="checkbox"
      :disabled="item.disable()"
      :value="{ date: item.value, employeeId: pr.id }"
      v-model="selected"
    />

  </td>
   <td>
     <input
      placeholder="Comment"
      v-model="formData.comments"
      class="comment"
    />
   </td> -->
                </tr>
              </tbody>
            </template>
          </v-simple-table>

          <!-- View modal  -->

          <Dialog ref="editModal" :title="'Edit Allowance'" :width="600">

            <v-row v-if="allowance !== null">

              <v-col class="mb-5">
                <p class="personal-name"><span class="text-caption">{{allowance.employeeName}}</span></p>
              </v-col>
              <v-col>
                <v-select v-model="formData.salaryPeriod" :items="salaryPeriods" item-value="id" item-text="description" label="Salary Period" return-object outlined dense />
              </v-col>
              <v-col v-if="allowance.request.timesheetItemType === 1" cols="6" class="pr-1">
                <v-text-field type="number"
                              v-model="formData.workHour"
                              :min="1"
                              :max="24"
                              dense
                              outlined
                              label="Work Hours"
                              oninput="(!validity.rangeOverflow||(value=24)) && (!validity.rangeUnderflow||(value=1)) && (!validity.stepMismatch||(value=parseInt(this.value)));" />
                <!-- <input
                  type="number"
                  v-model="formData.workHour"
                  placeholder="Work Hours"
                  min="0"
                  max="24"
                  value="comment.value"
                  class="input"
                  oninput="(!validity.rangeOverflow||(value=24)) && (!validity.rangeUnderflow||(value=0)) && (!validity.stepMismatch||(value=parseInt(this.value)));"
                /> -->
              </v-col>
              <v-col cols="6" v-if="allowance.request.timesheetItemType === 1">
                <v-text-field v-model="formData.startDate"
                              outlined
                              :rules="[v => (v >= formatDate(formData.salaryPeriod.startDate) &&  v <= formatDate(formData.salaryPeriod.endDate) ) || 'Not Within Salary Cycle']"
                              label="Work Date"
                              dense
                              :min="formatDate(formData.salaryPeriod.startDate)"
                              :max="formatDate(formData.salaryPeriod.endDate)"
                              type="date" />
              </v-col>
              <v-col cols="12" v-if="allowance.request.timesheetItemType === 0">
                <v-text-field v-model="formData.startDate"
                              outlined
                              :rules="[v => (v >= formatDate(formData.salaryPeriod.startDate) &&  v <= formatDate(formData.salaryPeriod.endDate) ) || 'Not Within Salary Cycle']"
                              label="Work Date"
                              dense
                              :min="formatDate(formData.salaryPeriod.startDate)"
                              :max="formatDate(formData.salaryPeriod.endDate)"
                              type="date" />
              </v-col>
              <v-col>
              </v-col>
            </v-row>
            <v-divider></v-divider>

            <v-row>
              <v-card width="700" elevation="0">
                <v-tabs v-model="tab">
                  <v-tab href="#tab-1" class="tab-title">Approver</v-tab>
                  <v-tab href="#tab-2">Log</v-tab>
                </v-tabs>
                <v-tabs-items v-model="tab">
                  <v-tab-item value="tab-1">
                    <v-card class="pt-2" flat>
                      <div v-if="loadingLogs" class="text-center">
                        <v-progress-circular indeterminate
                                             color="primary"></v-progress-circular>
                      </div>
                      <v-col v-else class="pt-3 pr-1 pl-1" :md="12" v-for="(approver, index) in approvers" :key="index">
                        <p class="text-left mb-1 approval-title">Waiting on ( {{approver.approver}} ): {{approver.approvers}}</p>

                        <!-- <v-select
                          :items="approver.approvers"
                          outlined
                          dense
                          :value="approver.approvers[0]"
                        ></v-select> -->
                      </v-col>
                    </v-card>
                  </v-tab-item>
                  <v-tab-item value="tab-2">
                    <v-card class="pt-2" flat>
                      <div v-if="loadingLogs" class="text-center">
                        <v-progress-circular indeterminate
                                             color="primary"></v-progress-circular>
                      </div>
                      <v-data-table v-else
                                    :headers='logHeaders'
                                    :items="logs">
                        <template v-slot:item.activityDate="{ item }">
                          <span>{{ item.activityDate | formatDate }}</span>
                        </template>
                      </v-data-table>
                    </v-card>
                  </v-tab-item>
                </v-tabs-items>
              </v-card>
            </v-row>
            <template v-slot:footer>
              <div class="d-flex">
                <Button :btnType="'Submit'" :disabled="approvalCount" :isLoading="isLoading" :label="'Update'" @onClick="update()" class="mr-2" />
                <Button :btnType="'Cancel'" :label="'Close'" @onClick="cancel('editModal')" />
              </div>
            </template>
          </Dialog>
          <!-- confirm delete modal  -->
              <Dialog ref="deleteModal" :title="'Are you sure you want to delete'" :width="400">
                <template v-slot:footer>
                  <Button :btnType="'Submit'" :label="'Yes'" @onClick="allowanceDelete" class="mr-2"/>
                  <Button :btnType="'Cancel'" :label="'No'" @onClick="cancel('deleteModal')" />
                </template>
              </Dialog>
        </v-container>
      </div>
    </div>
    <PersonnelSearch :dialog="personnelSearch" @selectPer="forward ($event)" @close="personnelSearch = false" @commentPer="getComment" :mType="'forward'" :type="'employee'" :title="'Personnel'">
      <Button :label="'Forward'"
              :btnType="'Submit'"
              @onClick="forwardRequest"
              :disabled="DelegateEmail === null"
              :isLoading="forwarding"
              class="mr-4" />
    </PersonnelSearch>

  </v-app>
</template>
<script>
import moment from 'moment'
import {
  // employeeService,
  requestService,
  // salaryComponentService,
  salaryPeriodService,
  messagingService
} from '@/services'
import PersonnelSearch from '@/components/ui/PersonnelSearchModal.vue'
import Button from '@/components/ui/form/Button.vue'
import Dialog from '@/components/ui/Dialog.vue'
// import TextField from '@/components/ui/form/TextField.vue'
import SelectField from '@/components/ui/form/SelectField.vue'
import { FETCH_ALLOWANCE } from '@/store/action-type'
export default {
  components: {
    Button,
    Dialog,
    // TextField,
    SelectField,
    PersonnelSearch
  },
  data () {
    return {
      search: '',
      searchVal: '',
      formData: {
        name: '',
        id: '',
        workHour: '',
        startDate: '',
        salaryPeriod: '',
        employeeId: '',
        salaryComponentId: ''
      },
      deleteData: {},
      loadingData: false,
      tab: null,
      loadingLogs: false,
      isLoading: false,
      salaryPeriods: [],
      approvalCount: false,
      valid: false,
      isSaving: false,
      personnelSearch: false,
      DelegateEmail: null,
      forwarding: false,
      pendingAllowances: [],
      alertMessage: '',
      alert: false,
      alertType: '',
      approvers: [],
      logs: [],
      allowance: null,
      headers: [],
      headers2: [],
      comment: '',
      logHeaders: [
        {
          text: 'Description',
          value: 'description'
        },
        {
          text: 'Action by',
          value: 'username'
        },
        {
          text: 'Comment',
          value: 'comments',
          width: '200px'
        },
        {
          text: 'Date',
          value: 'activityDate',
          width: '150px'
        }

      ]
    }
  },
  computed: {
    allowances () {
      return this.$store.getters.allowances
    },
    user () {
      return this.$store.getters.users
    }
  },
  methods: {
    showAlertMessage (message, type) {
      this.alertMessage = message
      this.alert = true
      this.alertType = type
      setTimeout(() => {
        this.alert = false
      }, 5000)
    },
    Add () {
      this.$router.push('/allowance/add')
    },
    edit (item, value) {
      this.approvers = []
      this.loadingLogs = true
      if (item.approvalCount === 0) { this.approvalCount = false } else { this.approvalCount = true }
      requestService.allowanceApprovalLogs(item.requestId).then(({ data }) => {
        this.approvers.push({
          approver: data.currenApprover[0].approver,
          approvers: `${data.currenApprover[0].approvers}`
        })
        this.logs = data.logs === null ? [] : data.logs
      }).catch(() => {

      }).finally(() => {
        this.loadingLogs = false
      })
      let request = this.filterVal(value.info, value.date)
      let salaryPeriodArray = this.salaryPeriods.filter(sp => sp.id === item.salaryPeriodId)
      item.request = request
      this.allowance = item
      this.$refs.editModal.openModal()
      this.formData.workHour = request.timesheetItemType === 1 ? request.value : undefined
      this.formData.salaryPeriod = salaryPeriodArray[0]
      this.formData.startDate = moment(request.requestDate).format('YYYY-MM-DD')
      this.formData.employeeId = item.employeeId
      this.formData.id = request.requestId
      this.formData.salaryComponentId = item.salaryComponentId
    },
    View (item) {
      this.$refs.allowanceViewModal.openModal()
      this.allowance = item
    },
    closeallowanceViewModal () {
      this.$refs.allowanceViewModal.closeModal()
    },
    // cancel () {
    //   this.$refs.editModal.closeModal()
    // },
    cancel (modal) {
      if (modal === 'editModal') {
        this.$refs.editModal.closeModal()
      }
      if (modal === 'deleteModal') {
        this.$refs.deleteModal.closeModal()
      }
    },
    update () {
      // this.isLoading = true
      const startDate = this.formatDate(this.formData.salaryPeriod.startDate)
      const endDate = this.formatDate(this.formData.salaryPeriod.endDate)
      if (this.formData.startDate >= startDate && this.formData.startDate <= endDate) {
        const ResumptionDate = moment(this.formData.startDate).add(1, 'days').format('YYYY-MM-DD')
        let data = {
          EmployeeId: this.formData.employeeId,
          salaryPeriodId: this.formData.salaryPeriod.id,
          startDate: this.formData.startDate,
          id: this.formData.id,
          resumptionDate: ResumptionDate,
          workHour: this.formData.workHour,
          // salaryComponent: this.formData,
          salaryComponentId: this.formData.salaryComponentId
        }
        console.log('Result', data)
        requestService.updateAllowance(data).then(result => {
          this.showAlertMessage('Allowance Updated successful', 'success')
          this.$refs.editModal.closeModal()
        }).catch(error => {
          window.console.log(error)
          this.showAlertMessage('Allowance Update failed', 'error')
        }).finally(() => {
          this.resetForm()
          this.isLoading = false
        })
      } else { this.showAlertMessage('Invalid date range', 'error') }
    },
    changeSalaryPeriod () {
      this.getGroupAllowanceRequest({ 'SalaryPeriodId': this.formData.salaryPeriod.id })
    },
    openPersonnelSearch (item) {
      this.personnelSearch = true
      this.item = item
    },
    openDeleteModal (item) {
      console.log('Item', item)
      this.$refs.deleteModal.openModal()
      this.deleteData.EmployeeId = item.employeeId
      this.deleteData.SalaryPeriodId = item.salaryPeriodId
      this.deleteData.SalaryComponentId = item.salaryComponentId
      this.deleteData.requestGuid = item.requestGuid;
    },
    allowanceDelete () {
      console.log('deleteData', this.deleteData)
      requestService.deleteAllowance(this.deleteData).then((result) => {
        this.showAlertMessage('Delete successfully', 'success')
        this.getGroupAllowanceRequest({})
      }).catch((error) => {
        console.log(error)
        this.showAlertMessage('Failed to delete', 'error')
      }).finally(() => {
        this.$refs.deleteModal.closeModal()
      })
    },
    forward (item) {
      this.DelegateEmail = item.emailAddress
      this.DelegateName = item.name      
    },
    getComment (comment) {
      this.comment = comment
    },
    forwardRequest () {
      this.forwarding = true
      let data = {
        'EmployeeId': this.item.employeeId,
        'SalaryPeriodId': this.item.salaryPeriodId,
        'SalaryComponentId': this.item.salaryComponentId,        
        'DelegateName': this.DelegateName,
        'delegateemail': this.DelegateEmail,
        'comment': this.comment
      }
      requestService.forwardRequest(data).then((result) => {
        this.showAlertMessage('Request forwarded successfully', 'success')
        const newArray = this.pendingAllowances.filter(i => i.id !== this.item.id)
        this.pendingAllowances = newArray
      }).catch((error) => {
        console.log(error)
        this.showAlertMessage('Failed to forward request', 'error')
      }).finally(() => {
        this.personnelSearch = false
        this.forwarding = false        
        this.DelegateName = null
        this.DelegateEmail = null
      })
    },
    sendMail () {
      messagingService.sendMessage().then((result) => {
        console.log(result)
      })
    },
    searchFunc () {
      const date = this.headers.dateArr
      const data = this.headers2.dataArr.filter(val => val.employeeName.toLowerCase().includes(this.searchVal.toLowerCase()) || val.allowance.toLowerCase().includes(this.searchVal.toLowerCase()) || val.approvalStatus.toLowerCase().includes(this.searchVal.toLowerCase()) || val.salaryPeriodDescription.toLowerCase().includes(this.searchVal.toLowerCase()))
      this.headers = { dataArr: data, dateArr: date }
    },
    dateArray (startDate, endDate, items) {
      let dateArr = []
      let dataArr = []
      let prevDate = moment(startDate)._d

      let nextDate = moment(endDate)._d

      prevDate = moment(prevDate).format('MM-DD-YYYY')
      nextDate = moment(nextDate).format('MM-DD-YYYY')

      var start = new Date(prevDate)
      var end = new Date(nextDate)

      items.forEach((item) => {
        item.beast = 'welcome'
        dataArr.push(item)
      })

      while (start <= end) {
        dateArr.push({
          text: moment(start).format('MMM Do'),
          day: moment(start).format('dddd'),
          value: moment(start).format('YYYY-MM-DD')
        })
        var newDate = start.setDate(start.getDate() + 1)
        start = new Date(newDate)
      }

      return { dataArr, dateArr }
    },
    getGroupAllowanceRequest (data) {
      this.loadingData = true
      requestService.groupedAllowance(data).then(result => {
        if (result.data.length !== 0) {
          let salaryPeriodId = result.data[0].salaryPeriodId
          const salaryPeriod = this.salaryPeriods.filter((per) => per.id === salaryPeriodId)

          this.addDateHeader(salaryPeriod[0].startDate, salaryPeriod[0].endDate, result.data)
        }
      }).catch((error) => {
        this.showAlertMessage(error.message, 'error')
      }).finally(() => {
        this.loadingData = false
      })
    },
    addDateHeader (startDate, endDate, items) {
      this.headers = this.dateArray(startDate, endDate, items)
      this.headers2 = this.dateArray(startDate, endDate, items)
    },
    filterVal (array, date) {
      let val = array.find(req => req.requestDate === date.value)
      return val || ''
    },
    formatDate (date) {
      return moment(date).format('YYYY-MM-DD')
    }
  },
  mounted () {
    this.$store
      .dispatch(FETCH_ALLOWANCE)
      .then(() => { })
      .catch((error) => {
        if (error) {
          this.showAlertMessage('Unable to display Allowance', 'error')
        }
      })
    salaryPeriodService
      .getAllSalaryPeriod()
      .then(result => {
        this.salaryPeriods = result.data
      })
      .catch(() => { })

    this.getGroupAllowanceRequest({})
  }
}
</script>
<style scoped>
  .search-btn {
    background-color: #e6e3e3;
    height: 40px;
    border-radius: 3px !important;
  }

  .alert {
    position: fixed;
    z-index: 9999;
    top: 5px;
    min-width: 50%;
  }

  .alert-success {
    color: #155724;
    background-color: #d4edda;
    border-color: #c3e6cb;
  }

  .alert-error {
    color: #721c24;
    background-color: #f8d7da;
    border-color: #f5c6cb;
  }

  .row-pointer >>> tbody tr :hover {
    cursor: pointer;
  }

  .iconAvatar {
    width: 122px;
  }

  .text-caption {
    font-weight: 600;
    font-size: 14px !important
  }

  .personal-name {
    text-align: left;
    font-weight: 600;
    font-size: 14px !important
  }

  .sticky-col {
    position: -webkit-sticky; /* for Safari */
    position: sticky;
    top: 0;
  }
  .allowance-day {
     color: blue;
     text-decoration: underline;
  }
  .cursor {
    cursor: pointer;
  }

    .cursor:hover {
      color: blue
    }

    .cursor .tooltiptext {
      visibility: hidden;
      width: 80px;
      background-color: black;
      color: #fff;
      text-align: center;
      border-radius: 6px;
      padding: 2px 0;
      font-size: 10px;
      /* Position the tooltip */
      position: absolute;
      z-index: 1;
    }

    .cursor:hover .tooltiptext {
      visibility: visible;
    }
</style>
